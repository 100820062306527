<template>
  <v-container fluid class="operations">
    <v-row>
      <v-col cols="12">
        <crm-table-pageable-new
            :headers="headers"
            :data="data"
            :config="config"
            :search-placeholder="config.searchPlaceholder"
            :loaded-options="options"
            :filters-dictionaries="filtersDictionaries"
            :available-filters.sync="availableFilters"
            :total-elements="totalElements"
            :total-pages="totalPages"
            @onChangeData="onChangeData"
        ></crm-table-pageable-new>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import crmTablePageableNew from '@/components/crm-table-pageable-new/index.vue';
import moment from 'moment';

export default {
  name: 'operations',
  components: {
    'crm-table-pageable-new': crmTablePageableNew,
  },
  data() {
    return {
      headers: [
        {
          value: 'transactionId', sortable: false, text: '№ транзакции', type: 'defaultItem', width: 150,
        },
        {
          value: 'created', sortable: true, text: 'Дата', type: 'defaultItem',
        },
        {
          value: 'typeId', sortable: false, text: 'Тип', type: 'defaultItem',
        },
        {
          value: 'orderNumber', sortable: false, text: '№ заказа', type: 'defaultItem', width: 100,
        },
        {
          value: 'bonuses', sortable: false, text: 'Бонус', type: 'defaultItem',
        },
        {
          value: 'client', sortable: false, text: 'Покупатель', type: 'defaultItem',
        },
        {
          value: 'expireDate', sortable: false, text: 'Срок действия', type: 'defaultItem', width: 150,
        },
        {
          value: 'event', sortable: false, text: 'Событие', type: 'defaultItem',
        },
        {
          value: 'systemName', sortable: false, text: 'Система', type: 'defaultItem',
        },
      ],
      data: [],
      config: {
        search: true,
        pageable: true,
      },
      filtersDictionaries: {},
      availableFilters: [
        {
          id: 'createdDateRange', name: 'Дата', type: 'dateRange', menu: false, active: true,
        },
        {
          id: 'types', name: 'Тип', type: 'select', active: false,
        },
      ],
      totalElements: 0,
      totalPages: 0,
      options: {
        page: 1,
        size: 10,
        sort: 'created,desc',
      },
      exportLoading: false,
    };
  },
  async created() {
    await this.getTypes();
    this.$loading(false);
    this.onChangeData(this.$route.query);
  },
  methods: {
    getTypes() {
      return new Promise((resolve) => {
        this.$loading(true);
        this.$billing.get('BonusOperation/OperationTypes').then((res) => {
          this.appealStatuses = res;
          this.$set(this.filtersDictionaries, 'types', res.map((i) => ({
            value: i.id,
            label: i.name,
          })));
          resolve();
        });
      });
    },
    onChangeData(options) {
      let resultOptions = { ...options };
      if (!Object.values(options).length) {
        resultOptions = { ...this.options };
      }
      this.$router.replace({
        path: this.$route.path,
        params: {},
        query: resultOptions,
      }).catch(() => {});
      this.options = { ...resultOptions };
      this.getOperations();
    },
    getOperations() {
      const params = this.$route.query;

      this.$loading(true);
      this.$billing.get('BonusOperation', {
        params: {
          PageNumber: params.page,
          PageSize: params.size,
          TypeIds: params.types,
          Sorting: params.sort,
          DateFrom: params.createdDateRange && params.createdDateRange.split(',')[0],
          DateUntil: params.createdDateRange && params.createdDateRange.split(',')[1],
          'Search.Value': params.search,
        },
      }).then((response) => {
        this.totalElements = response.recordsFiltered;
        this.totalPages = Math.ceil(response.recordsFiltered / response.pageSize);
        this.data = response.data.map((i) => ({
          ...i,
          created: i.created ? moment(i.created).format('DD.MM.YYYY HH:mm') : i.created,
          expireDate: i.expireDate ? moment(i.expireDate).format('DD.MM.YYYY HH:mm') : i.expireDate,
        }));
      }).finally(() => {
        this.$loading(false);
      });
    },
  },
};
</script>

<style lang="scss">
.operations {

}
</style>
